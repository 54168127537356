import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import ListPlans from './ListSubscription';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY!);

const LisSubscriptiopWrap = () => {
  return (
    <Elements stripe={stripePromise}>
      <ListPlans />
    </Elements>
  );
};

export default LisSubscriptiopWrap;
