import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../sidebar/Footer";
import SocialMediaIcons from "./SocialMedia";

const ChooseSubscriptionPage = () => {
  const [selectedCard, setSelectedCard] = useState<string | null>(null);
  const navigate = useNavigate();

  const handleUserClick = () => {
    setSelectedCard("user");
    navigate("/pk/subscription/users");
  };

  const handleChefClick = () => {
    setSelectedCard("chef");
    navigate("/pk/subscription/chefs");
  };

  return (
    <section className="container">
      {/* Header */}
      <section className="head row top-header-sales-page align-items-center">
        {/* Logo and Welcome Text Section */}
        <div className="col-12 col-md-8 d-flex align-items-center">
          <div className="col-3 col-md-2 d-flex align-items-center justify-content-center">
            <img
              className="img-fluid"
              src="../../assets/images/homechef-logo.svg"
              alt="Logo"
              style={{ height: "100px" }}
            />
          </div>
          <div className="col-9 col-md-10 d-flex align-items-center justify-content-end">
            <h5 className="fs-5 fs-md-3 fw-bold text-success mb-0 text-center">
              HomeChef360 Subscriptions
            </h5>
          </div>
        </div>

        {/* Social Media Icons Section */}
        <div className="col-12 col-md-4 d-flex align-items-center justify-content-end">
          <SocialMediaIcons />
        </div>
      </section>

      {/* Introductory Text */}
      <div className="row mx-3">

        <p className="text-muted text-justify">
          HomeChef360 is a dynamic platform connecting talented home chefs with food lovers seeking authentic, home-cooked meals. It empowers chefs to showcase their culinary skills, earn an income, and build trust through certifications, while customers enjoy diverse, personalized, and locally crafted dishes.
        </p>
        <p className="text-muted text-center">
          <strong className="text-success fs-5 py-5">
            Our Subscription
          </strong>
        </p>
        <p className="text-muted text-justify">
          At HomeChef360, we offer flexible subscription plans designed to meet the needs of chefs and food enthusiasts alike. Whether you're a culinary expert looking to grow your business or a food lover exploring gourmet experiences, our subscriptions provide the tools and opportunities you need to thrive.
        </p>
      </div>

      {/* Subscription Cards */}
      <div className="row justify-content-center py-3">
        {/* User Card */}
        <div className="col-12 col-sm-6 col-md-4 mb-4">
          <div
            className={`card p-4 text-center ${selectedCard === "user" ? "bg-light-gray" : "bg-white"
              }`}
            style={{
              boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.1)",
              borderRadius: "12px",
              cursor: "pointer",
              transition: "transform 0.3s ease-in-out",
              backgroundColor: selectedCard === "user" ? "#e0e0e0" : "#fff",
              height: "100%", // Ensures consistent height
            }}
            onClick={handleUserClick}
          >
            <div className="plan-header d-flex flex-column align-items-center">
              <img
                className="img-fluid mb-3"
                src="../../assets/images/user-plan.png"
                alt="User"
                style={{ height: "90px", width: "90px" }}
              />
              <h5 className="fs-5 fw-bold">User</h5>
              <p className="text-muted text-justify">
                HomeChef360 lets you enjoy authentic, home-cooked meals crafted by talented chefs in your community. Discover personalized dishes and savor the joy of homemade flavors delivered to your table.
              </p>

            </div>
            <div className="card-footer-subscription mt-auto">
              <button className="btn btn-success rounded-5">Choose User</button>
            </div>
          </div>
        </div>

        {/* Chef Card */}
        <div className="col-12 col-sm-6 col-md-4 mb-4">
          <div
            className={`card p-4 text-center ${selectedCard === "chef" ? "bg-light-gray" : "bg-white"
              }`}
            style={{
              boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.1)",
              borderRadius: "12px",
              cursor: "pointer",
              transition: "transform 0.3s ease-in-out",
              backgroundColor: selectedCard === "chef" ? "#e0e0e0" : "#fff",
              height: "100%", // Ensures consistent height
            }}
            onClick={handleChefClick}
          >
            <div className="plan-header d-flex flex-column align-items-center">
              <img
                className="img-fluid mb-3"
                src="../../assets/images/chef-plan.png"
                alt="Chef"
                style={{ height: "90px", width: "90px" }}
              />
              <h5 className="fs-5 fw-bold">Chef</h5>
              <p className="text-muted text-justify">
                HomeChef360 empowers you to turn your culinary passion into income. Showcase your skills, gain trust with certifications, and connect with food lovers who value your craft.
              </p>

            </div>
            <div className="card-footer-subscription mt-auto">
              <button className="btn btn-success rounded-5">Choose Chef</button>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </section>
  );
};

export default ChooseSubscriptionPage;
