import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import ListPlans from "./files/ListSubscription";
import LisSubscriptiopWrap from "./files/LisSubscriptiopWrap";

const SubscriptionPage = () => {
  const { id } = useParams(); 
  useEffect(() => {
    if (id && id !== "default") {
      localStorage.setItem("userId", id); // Store `id` in localStorage
    } else {
      localStorage.removeItem("userId"); // Remove if no valid `id`
    }
  }, [id]);
  return (
    <div>
 
      <LisSubscriptiopWrap /> 
    </div>
  );
};

export default SubscriptionPage;
