import { useNavigate, useParams } from "react-router-dom";
import { getPaymentStatus } from "../core/_requests";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import ListPlans from "./ListSubscription";

const PaymentSuccess = () => {
  const navigate = useNavigate();
  const sessionId = localStorage.getItem("sessionId");
  const [result, setResult] = useState<{ message: string; status: boolean } | null>(null);
  const userId = localStorage.getItem("userId");
  const subscribedActivePlanId = localStorage.getItem("subscribedPlanId");
  console.log("*********************************************************************")
  console.log("result PaymentSuccess", result)
  console.log("*********************************************************************")
  const checkPaymentStatus = async () => {
    if (!sessionId) return;

    try {
      const res = await getPaymentStatus(sessionId, subscribedActivePlanId);
      console.log("*********************************************************************");
      console.log("Payment status response:", res);
      console.log("*********************************************************************");


      if (res.status === true) {
        console.log("*********************************************************************")
        console.log("ressetResult ", res)
        console.log("*********************************************************************")
        setResult(res);
        toast.success(res?.message, { autoClose: 5000 });


      } else {
        toast.error(res?.message, { autoClose: 5000 });
      }
    } catch (error: any) {
      console.error("Error checking payment status:", error);
    } finally {
      localStorage.removeItem("sessionId");
      localStorage.removeItem("subscribedPlanId");

    }
  };

  useEffect(() => {
    checkPaymentStatus();
  }, [sessionId, subscribedActivePlanId]);

  return (
    <section className="container-fluid">
      <ListPlans />
      <div className="overlay"></div>
      <div className="drawer ps-5 addon-wrap">

        <div className="alert-wrap">
          <img
            className="ico mb-2"
            src="../../assets/images/alert-success-ico.svg"
            alt="success"
          />
          <h1 className="mb-4">Awesome!</h1>
          <p className="mid-gray mb-2 ps-3">
            <small>{result?.message || "Plan activated successfully"} </small>
          </p>
          <button
            className="btn btn-cancel rounded-5 btn-outline-primary mt-3" 
            onClick={() => navigate(`/info/${userId}`)}
          >
            Back to Home
          </button>
        </div>
      </div>

    </section>
  );
};

export default PaymentSuccess;
