import React from 'react';
import { FaFacebook, FaInstagram, FaLinkedin, FaYoutube, FaGlobe } from 'react-icons/fa';

// Define the interface for social media link type
interface SocialMediaLink {
  platform: string;
  url: string;
  icon: React.ReactElement;
  color: string; 

}

// Array of social media links with icons and colors, including the website link
const socialMediaLinks: SocialMediaLink[] = [
  { platform: 'Facebook', url: 'https://www.facebook.com/HomeChef360/', icon: <FaFacebook />, color: '#1877F2' },
  { platform: 'Instagram', url: 'https://www.instagram.com/Homechef360us/', icon: <FaInstagram />, color: '#E1306C' },
  { platform: 'LinkedIn', url: 'https://www.linkedin.com/company/homechef360', icon: <FaLinkedin />, color: '#0077B5' },
  { platform: 'YouTube', url: 'https://www.youtube.com/@HomeChef360', icon: <FaYoutube />, color: '#FF0000' },
  { platform: 'Website', url: 'https://www.homechef360.com', icon: <FaGlobe />, color: '#5DA571' }, // Website entry
];

// The component displaying social media icons
const SocialMediaIcons: React.FC = () => {
  // Handle click event to open the social media URL in a new tab
  const handleIconClick = (url: string) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <div className="mt-2 align-items-center" style={{ display: 'flex', gap: '1rem' }}>
      {socialMediaLinks.map((link) => (
        <button
          key={link.platform}
          onClick={() => handleIconClick(link.url)}
          style={{
            background: 'none',
            border: 'none',
            cursor: 'pointer',
            fontSize: '1.5rem',
            color: link.color,
            transition: 'transform 0.3s ease-in-out', // Smooth transition for zoom effect
          }}
          aria-label={link.platform} // For accessibility purposes
          onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.2)')} // Zoom in
          onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')} // Zoom out
        >
          {link.icon}
        </button>
      ))}
    </div>
  );
};

export default SocialMediaIcons;
