import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  ReactNode,
} from "react";
import { getPlans } from "../core/_requests";
import { Plan } from "../core/_models";

// Plan context type definition
interface PlanContextType {
  plans: Plan[];
  setPlans: React.Dispatch<React.SetStateAction<Plan[]>>;
}

// PlanContext creation with default value of undefined
const PlanContext = createContext<PlanContextType | undefined>(undefined);

// Custom hook to use the PlanContext
export const usePlan = (): PlanContextType => {
  const context = useContext(PlanContext);
  if (!context) {
    throw new Error("useChef must be used within a PlanProvider");
  }
  return context;
};

// ChefProvider props type definition
interface ChefProviderProps {
  children: ReactNode;
}

// PlanProvider component
const PlanProvider: React.FC<ChefProviderProps> = ({ children }) => {
  const userId = localStorage.getItem("userId");

  // Plans state initialization
  const [plans, setPlans] = useState<Plan[]>([]);

  useEffect(() => {
    const fetchPlans = async () => {
      if (userId) {
        try {
          const res = await getPlans(userId); // Fetch plans using the getPlans function
          setPlans(res?.data || []); // Set plans or an empty array if no data
        } catch (error) {
          console.error("Error fetching plans:", error); // Handle errors gracefully
        }
      }
    };
  
  }, [userId]); // Effect runs when userId changes

  return (
    <PlanContext.Provider value={{ plans, setPlans }}>
      {children}
    </PlanContext.Provider>
  );
};

export default PlanProvider;
