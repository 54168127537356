import React, { useEffect, useState } from "react";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {
  createCheckoutSession,
  createTrialSubscription,
  ProfileDetails,
} from "../core/_requests";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

// Load your Stripe publishable key
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || "");

interface ApiResponse {
  success: boolean;
}

const TrialPlan = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { selectedPlan, activePlanDetails, planId, userProfile } =
    location.state || {}; // Get the selected plan and other details
  // State for user inputs
  const [name, setName] = useState(userProfile?.name || "");
  const [email, setEmail] = useState(userProfile?.email || "");
  const [country, setCountry] = useState(
    userProfile?.addressbooks[0]?.country || ""
  );
  const [autoCharge, setAutoCharge] = useState(false);

  const user_id = localStorage.getItem("userId");

  console.log(
    "*********************************************************************"
  );
  console.log("selectedPlan -- trial plan page", selectedPlan);
  console.log("userProfile -- trial plan page", userProfile);
  console.log(
    "selectedPlan, activePlanDetails, planId -- trial plan page",
    activePlanDetails,
    planId
  );
  console.log(
    "*********************************************************************"
  );

  if (!selectedPlan) {
    return <p>No plan data found.</p>;
  }
  const handleTrialSubscription = async () => {
    if (!stripe || !elements) {
      console.error("Stripe or Elements not loaded");
      return;
    }
    setIsProcessing(true);

    try {
      const cardElement = elements.getElement(CardElement);
      if (!cardElement) {
        alert("Card element not found. Please try again.");
        return;
      }

      // Create payment method in Stripe
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
      });

      if (error) {
        alert(error.message);
        return;
      }

      if (!paymentMethod) {
        alert("Payment method creation failed. Please try again.");
        return;
      }

      const amount = selectedPlan.amount;
      const type = selectedPlan.type;
      const validity = selectedPlan.validity;
      const is_trial = selectedPlan.is_trial;
      const originalAmount = selectedPlan.originalAmount;
      const paymentType = 4;
      const data = {
        paymentMethodId: paymentMethod.id,
        planId,
        validity,
        amount,
        type,
        name,
        email,
        country,
        user_id,
        is_trial,
        paymentType,
        originalAmount,
        autoCharge, // Include the autoCharge option
      };

      const response = await createCheckoutSession(data);

      if (response.status === true) {
        toast.success(response.message);
        navigate(`/info/${user_id}`);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.error("Error during subscription:", error);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className="trial-plan-container">
      <h2 className="trial-plan-heading text-success fw-bold">
        Subscribe to Trial Plan
      </h2>
      {/* <p className="text-success fw-bold text-center mb-2">{selectedPlan.validity} {selectedPlan.validityCount} for ${selectedPlan.amount} </p> */}
      <p className="text-success fw-bold text-center mb-2">
  Enjoy a 30-day free trial! Afterward, your subscription will automatically renew at ${selectedPlan.amount} per {selectedPlan.validityCount}.
</p>


      <p className="d-none">Plan ID: {planId}</p>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleTrialSubscription();
        }}
        className="trial-plan-form"
      >
        <label className="label">Name</label>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
          className="form-control mb-2"
          placeholder="Enter your name"
          readOnly
        />
        <label className="label">Email</label>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          className="form-control mb-2"
          placeholder="Enter your email"
          readOnly
        />
        <label className="label">Country</label>
        <input
          type="text"
          value={country}
          onChange={(e) => setCountry(e.target.value)}
          required
          className="form-control mb-2"
          placeholder="Enter your country"
          // readOnly
        />
        <div className="card-element-container">
          <label className="label">Card Details</label>
          <CardElement
            className="card-element"
            options={{ hidePostalCode: true }}
          />
        </div>
        <div className="auto-charge-container">
          <label className="checkbox-label">
            <input
              type="checkbox"
              checked={autoCharge}
              onChange={() => setAutoCharge(!autoCharge)}
              className="checkbox-input"
            />
            Enable auto renewal
          </label>
        </div>
        <button
          type="submit"
          disabled={isProcessing || autoCharge === false}
          className={`submit-btn ${
            isProcessing || autoCharge === false
              ? "text-danger"
              : "text-white fw-bold"
          }`}
        >
          {isProcessing ? (
            <span className="text-success">Processing</span>
          ) : (
            "Activate Trial"
          )}
        </button>
      </form>
    </div>
  );
};

const TrialPlanWithElements = () => {
  return (
    <Elements stripe={stripePromise}>
      <TrialPlan />
    </Elements>
  );
};

export default TrialPlanWithElements;
