import React, { useContext, useEffect, useState } from "react";
import {
  createCheckoutSession,
  getCoupons,
  getPlans,
  ProfileDetails,
  saveCardDetailsTostripe,
  verifyStudentDetails,
} from "../core/_requests";
import { ActivePlanDetails, Plan, PlanDetail } from "../core/_models";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { Modal } from "react-bootstrap";
import "../../../assets/custom-style.css";
import Header from "../../sidebar/Header";
import Footer from "../../sidebar/Footer";
import { loadStripe } from "@stripe/stripe-js";
import PlanUnsubscribe from "./PlanUnsubscribe";
import { useModalContext } from "../../Context/ModalContext";
import OfferBanner from "./OfferText";
interface UserProfile {
  name: string;
  walletBalance: number;
  email?: string;
  profile?: string;
  contactNumber?: string;
}

type ButtonTexts = Record<string, string>;
const ListPlans = () => {
  const { id } = useParams();
  // const { plans,setPlans } = usePlan();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [plans, setPlans] = useState<Plan[]>([]);
  const [subscribedActivePlan, setSubscribedActivePlan] = useState<
    Plan | null | any
  >(null);
  const [subscribingPlan, setSubscribingPlan] = useState<string | null>(null);
  const [selectedPlanOption, setSelectedPlanOption] = useState<{
    [key: string]: PlanDetail | null;
  }>({});
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
  const [walletBalance, setWalletBalance] = useState<any>();
  const [showSubscribeConformationModal, setShowSubscribeConformationModal] =
    useState(false);
  const [walletInputAmount, setWalletInputAmount] = useState<number>(0);
  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLIC_KEY || ""
  );
  console.log(
    "*********************************************************************"
  );
  console.log("subscribingPlan    check id or else", subscribingPlan);
  console.log(
    "*********************************************************************"
  );
  console.log("subscribedActivePlan", subscribedActivePlan);
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [activeFeatureId, setActiveFeatureId] = useState<number | null>(null);
  const { showUnsubscribeModal, setShowUnsubscribeModal } = useModalContext();
  const [isChangingPlan, setIsChangingPlan] = useState(false);
  const [message, setMessage] = useState("");
  const [activeMessageVisible, setActiveMessageVisible] = useState(false);

  const handleClick = () => {
    setActiveMessageVisible(true); // Show the "Active" message when the "Active" button is clicked
  };

  const handleResetMessage = () => {
    setActiveMessageVisible(false); // Hide the message when a different action is taken
  };
  const handleShowUnsubscribeModal = () => {
    setShowUnsubscribeModal(true); // Show modal directly
  };

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (activeFeatureId !== null) {
      // Set a timeout to hide the tooltip after 10 seconds
      timer = setTimeout(() => {
        setActiveFeatureId(null);
      }, 3000); // 3 seconds
    }

    // Cleanup the timeout if the component unmounts or activeFeatureId changes
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [activeFeatureId]);

  const handleInfoClick = (id: number) => {
    setActiveFeatureId((prevId) => (prevId === id ? null : id)); // Toggle the tooltip
  };
  useEffect(() => {
    if (subscribedActivePlan?.id) {
      localStorage.setItem("subscribedPlanId", subscribedActivePlan.id);
      console.log(`Stored Plan ID: ${subscribedActivePlan.id}`);
    }
  }, [subscribedActivePlan]);
  useEffect(() => {
    // Set `id` to localStorage as `userId`
    if (id) {
      localStorage.setItem("userId", id);
    }
  }, [id]);
  const fetchProfileDetails = async () => {
    try {
      const user_id = id;
      const res = await ProfileDetails(user_id);
      setUserProfile(res?.data);
      const balance = res.data?.walletBalance || 0;
      setWalletBalance(balance * 0.8); // Set to 20% of the returned balance
    } catch (err) {
      console.error("Error fetching profile details:", err);
      setWalletBalance(0); // Handle errors gracefully
    }
  };

  if (!process.env.REACT_APP_STRIPE_PUBLIC_KEY) {
    throw new Error("Stripe public key is not defined");
  }
  const [showSubscribeModal, setShowSubscribeModal] = useState(false);
  const [showTrialSubscribeModal, setShowTrialSubscribeModal] = useState(false);

  const handleTrialSubscribeModal = () =>
    setShowTrialSubscribeModal((prev) => !prev);
  const [activePlanDetails, setActivePlanDetails] = useState<ActivePlanDetails>(
    {
      id: "",
      name: "",
      amount: 0,
      originalAmount: 0,
      type: 0,
      validity: 0,
      button: "",
      is_trial: false,
      plan_end_date: "",
      plan_start_date: "",
      yearDifferenceAmount: "",
      monthDifferenceAmount: "",
      description: "",
      plan_details: [],
    }
  );
  console.log(
    "*********************************************************************"
  );
  console.log("activePlanDetails", activePlanDetails);
  console.log(
    "*********************************************************************"
  );

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Adds a smooth scrolling effect
    });
  };

  const handleShowSubscribeModal = async (plan: any) => {
    // Check if a plan option is selected for the given plan ID
    if (!selectedPlanOption[plan.id]) {
      toast.error("Please select a plan option before subscribing");
      return; // Prevent further actions if no plan option is selected
    }
    try {
      // Check for the specific plan ID
      if (plan.id === "918e11df-f3ca-4674-88cd-c407e4a2dd5f") {
        const requestData = {
          type: 1,
        };

        const data = await verifyStudentDetails({ requestData });

        if (data.status === true) {
          toast.success(data.message, { autoClose: 5000 });
          fetchPlans(); // Optionally refresh plans
          setActivePlanDetails(plan); // Set the plan details
          setShowSubscribeModal(true); // Open the modal
        } else {
          toast.error(data.message, { autoClose: 5000 });
        }
      } else {
        // General behavior for other plans
        setActivePlanDetails(plan); // Set the plan details
        setShowSubscribeModal(true); // Open the modal
      }
    } catch (error) {
      console.error("Error during verification process:", error);
      toast.error("An error occurred during verification");
    } finally {
      setSubscribingPlan(null);
    }
  };

  const fetchPlans = async () => {
    try {
      console.log("Fetching plans for id:", id);
      const res = await getPlans(id);
      const plansData: Plan[] = res?.data || []; // Specify the type for plansData
      setPlans(plansData);
      const activePlans = plansData.filter((plan: Plan) => plan.isPlanActive); // Filter active plans
      const initialSelectedOptions: { [key: string]: PlanDetail | null } = {}; // Ensure the type is specified

      activePlans.forEach((activePlan: Plan) => {
        // Loop through active plans
        console.log("Processing activePlan:", activePlan);
        setSubscribedActivePlan(activePlan);

        // Attempt to find the active detail first
        const activeDetail = activePlan.plan_details.find(
          (detail: PlanDetail) => detail.isActive
        );
        console.log(
          "*********************************************************************"
        );
        console.log("activeDetail", activeDetail);
        console.log(
          "*********************************************************************"
        );
        // If no active detail found, fall back to the first detail
        const selectedDetail = activeDetail || activePlan.plan_details[0];
        console.log(
          "*********************************************************************"
        );
        console.log("selectedDetail", selectedDetail);
        console.log(
          "*********************************************************************"
        );
        // if (selectedDetail) {
        //   initialSelectedOptions[activePlan.id] = {
        //     amount: selectedDetail.amount,
        //     originalAmount: selectedDetail.originalAmount,
        //     validity: selectedDetail.validity,
        //     validityCount: selectedDetail.validityCount,
        //     type: selectedDetail.type,
        //     isActive: selectedDetail.isActive,
        //     is_trial: selectedDetail.is_trial,
        //   };

        //   console.log(
        //     "Initial selected option set for plan:",
        //     activePlan.id,
        //     initialSelectedOptions[activePlan.id]
        //   );
        // }
      });

      // console.log("Final initialSelectedOptions:", initialSelectedOptions);
      // setSelectedPlanOption(initialSelectedOptions);
    } catch (error) {
      console.error("Error fetching plans:", error);
    } finally {
      setLoading(false);
      console.log("Loading state set to false");
    }
  };

  const handleCloseSubscribeModal = () => {
    setShowSubscribeModal(false);
    // Check if walletBalance is not zero, then show confirmation modal
    if (walletBalance !== 0) {
      setShowSubscribeConformationModal(true);
    }
  };

  const handleCloseSubscribeConformationModal = () => {
    setWalletInputAmount(0);
    setShowSubscribeConformationModal(false);
  };
  const [paymentType, setPaymentType] = useState<any>(1); // 0 for Wallet, 1 for Card
  const [buttonTexts, setButtonTexts] = useState<ButtonTexts>(
    plans.reduce((acc, plan) => {
      acc[plan.id] = plan.button || "Subscribe";
      return acc;
    }, {} as ButtonTexts)
  );

  const handlePlanOptionChange = (
    planId: string,
    amount: number,
    validity: number,
    validityCount: string,
    type: any,
    isActive: boolean,
    is_trial: boolean,
    originalAmount: number
  ) => {
    console.log("Updating option for planId: ", planId, amount, validity);
    if (isActive) {
      setActiveMessageVisible(true); // Show the "Active" message if plan is already active
    } else {
      setActiveMessageVisible(false); // Hide the "Active" message if plan is not active
    }
    setIsChangingPlan(true); // Set flag to indicate plan is being changed
    setSelectedPlanOption((prevState) => {
      const newState = { ...prevState };

      // Update the selected plan option
      newState[planId] = {
        amount,
        validityCount,
        validity,
        type,
        isActive,
        is_trial,
        originalAmount,
      };

      // Update button text for the specific plan
      setButtonTexts((prevTexts) => {
        const updatedTexts = { ...prevTexts };
        updatedTexts[planId] =
          amount === 0
            ? "Activate Trial"
            : plans.find((p) => p.id === planId)?.button || "Subscribe";
        return updatedTexts;
      });

      return newState;
    });
    setIsChangingPlan(false); // Reset flag after the update is done
    // Calculate offerText and offerAmount based on coupon type
    if (offerToday && type == 1) {
      if (coupons.couponType === 1) {
        // Percentage discount
        const discount = amount - (amount * coupons.amount) / 100;
        setOfferAmount(discount.toFixed(2));
        setOfferText(
          `(${coupons.amount}% off, new price $${discount.toFixed(2)})`
        );
      } else if (coupons.couponType === 2) {
        // Fixed amount discount
        const discount = amount - coupons.amount;
        setOfferAmount(discount.toFixed(2));
        setOfferAmount(discount.toFixed(2));
        setOfferText(
          `($${coupons.amount} off, new price $${discount.toFixed(2)})`
        );
      } else {
        // No discount
        setOfferAmount(null);
        setOfferText(null);
      }
    }
  };

  useEffect(() => {
    const selectedPlanAmount = offer
      ? offerAmount
      : selectedPlanOption[activePlanDetails?.id || ""]?.amount || 0;

    if (walletBalance >= selectedPlanAmount) {
      // Wallet covers full amount
      setWalletInputAmount(selectedPlanAmount);
      setPaymentType(1);
    } else {
      // Partial wallet payment + card
      setWalletInputAmount(walletBalance);
      setPaymentType(3);
    }
  }, [activePlanDetails, walletBalance, selectedPlanOption]);

  useEffect(() => {
    fetchPlans();
    fetchProfileDetails();
    fetchCoupons();
  }, [id]);

  const [coupons, setCoupons] = useState<any>({});
  const [offerDescription, setOfferDescription] = useState<any>("");
  const [offerText, setOfferText] = useState<any>("");
  const [offerAmount, setOfferAmount] = useState<any>("");
  const [offerToday, setOfferToday] = useState<boolean>(false);
  const fetchCoupons = async () => {
    const response = await getCoupons(1);
    setCoupons(response.data[0]);
  };
  useEffect(() => {
    const now = new Date();
    // Convert current date to New York timezone (EST/EDT)
    const todayDate = new Date(
      now.toLocaleString("en-US", { timeZone: "America/New_York" })
    );
    // Define offer start and end dates in EST
    const startDate = new Date(coupons?.startDate);
    const endDate = new Date(coupons?.endDate);

    // Debugging logs
    console.log(
      "*********************************************************************"
    );
    console.log("coupons:", coupons);
    console.log("Current Date (Local):", now);
    console.log("Converted EST Date:", todayDate);
    console.log("Offer Start Date (EST):", startDate);
    console.log("Offer End Date (EST):", endDate);
    console.log(
      "todayDate >= startDate && todayDate <= endDate:",
      todayDate >= startDate && todayDate <= endDate
    );
    console.log(
      "*********************************************************************"
    );

    // Comparison logic for active offer
    if (todayDate >= startDate && todayDate <= endDate) {
      setOfferToday(true);
      setOfferDescription(coupons?.description);
    } else {
      setOfferDescription(null); // No offer outside these dates
    }
  }, [coupons]);

  const offer =
    offerToday &&
    offerAmount &&
    selectedPlanOption[activePlanDetails?.id || ""]?.type == 1;

  const handleSubscribe = async (planId: string) => {
    if (!selectedPlanOption[planId]) {
      toast.error("Please select a plan option before subscribing");
      return;
    }

    const selectedOption = selectedPlanOption[planId]!;
    const { amount, validity, type } = selectedOption;

    // Determine if the trial is being activated
    const isTrial = buttonTexts[planId] === "Activate Trial";
    let effectivePaymentType = isTrial ? 4 : 2;
    let finalAmount = offer ? offerAmount : amount;
    let finalWalletInputAmount = 0;
    console.log(
      "*********************************************************************"
    );
    console.log("walletInputAmount", walletInputAmount);
    console.log("walletBalance", walletBalance);
    console.log("finalAmount", finalAmount);
    console.log(
      "*********************************************************************"
    );

    if (!isTrial) {
      if (paymentType === 1) {
        // Wallet payment selected
        if (walletInputAmount <= walletBalance && walletBalance != 0) {
          console.log(
            "walletInputAmount <= walletBalance && walletBalance != 0",
            walletInputAmount <= walletBalance && walletBalance != 0
          );

          if (walletInputAmount >= finalAmount) {
            console.log(
              "walletInputAmount >= finalAmount",
              walletInputAmount >= finalAmount
            );

            // Fully paid with wallet
            effectivePaymentType = 1;
            finalWalletInputAmount = finalAmount;
            // finalAmount = 0;
          } else {
            console.log("walletInputAmount >= finalAmount else 33");

            // Partial payment with wallet and card
            effectivePaymentType = 3;
            finalWalletInputAmount = walletInputAmount;
            finalAmount = finalAmount - walletInputAmount;
          }
        } else {
          // Invalid wallet input amount
          toast.error(
            "Entered wallet amount exceeds available wallet balance.",
            { autoClose: 5000 }
          );
          return;
        }
      } else if (paymentType === 2) {
        console.log("paymentType === 2", paymentType === 2);

        // Card payment selected
        effectivePaymentType = 2; // Fully paid with card
        finalWalletInputAmount = 0; // No wallet amount used
      }
    }

    setSubscribingPlan(planId);
    handleCloseSubscribeModal();
    handleCloseSubscribeConformationModal();

    try {
      const sessionData = await createCheckoutSession({
        planId,
        originalAmount: amount,
        amount: parseInt(finalAmount),
        user_id: id,
        type,
        validity,
        paymentType: effectivePaymentType,
        walletInputAmount: isTrial ? 0 : finalWalletInputAmount,
        is_trial: isTrial,
        ...(offer && { coupon_id: coupons?.primaryId }),
      });

      console.log(
        "*********************************************************************"
      );
      console.log("sessionData", sessionData);
      console.log(
        "*********************************************************************"
      );

      if (sessionData.status === true) {
        toast.success(sessionData.message, { autoClose: 5000 });
        scrollToTop();
        setSelectedPlanOption({});
        setButtonTexts((prevTexts) => {
          const updatedTexts = { ...prevTexts };
          updatedTexts[planId] = "Cancel";
          return updatedTexts;
        });
        fetchPlans();
        fetchProfileDetails();
      } else {
        toast.error(sessionData.message, { autoClose: 5000 });
      }

      if (!isTrial) {
        const stripe = await stripePromise;
        if (stripe) {
          localStorage.setItem("sessionId", sessionData.id);
          const { error } = await stripe.redirectToCheckout({
            sessionId: sessionData.id,
          });
          if (error) {
            toast.error("Error processing payment. Please try again.", {
              autoClose: 5000,
            });
          }
        }
      }
    } catch (error) {
      console.error("Error during subscription/payment process:", error);
    } finally {
      setSubscribingPlan(null);
    }
  };

  const handleTrialSubscription = (planId: string) => {
    const selectedPlan = selectedPlanOption[planId]; // Retrieve the selected plan details using the planId

    if (!selectedPlan) {
      toast.error("Selected plan not found.");
      return;
    }

    // If you have an active plan details, ensure it's included
    const activePlanDetails = selectedPlanOption[planId]; // Replace with your logic to get active plan details if necessary

    // Navigate to the trial plan page and pass the selected plan data and planId via state
    navigate("/trial-plan", {
      state: { selectedPlan, activePlanDetails, planId, userProfile },
    });
  };

  useEffect(() => {
    // You can log or check if the changes have been applied correctly
    console.log("Selected plan option updated: ", selectedPlanOption);
  }, [selectedPlanOption]); // Ensure this triggers whenever selectedPlanOption changes

  return (
    <section className="container py-4">
      {/* Header */}
      <Header
        userProfile={userProfile}
        walletBalance={walletBalance}
        subscribedActivePlan={subscribedActivePlan}
      />
      <OfferBanner offerText={offerDescription} />

      {/* Plan List */}
      <div className="row justify-content-center">
        {loading ? (
          <div className="col-12 text-center">
            <p>Loading plans...</p>
          </div>
        ) : plans.length === 0 ? (
          <div className="col-12 text-center">
            <p>No plans available at the moment.</p>
          </div>
        ) : (
          plans.map((plan) => {
            const { id, name, description } = plan;

            return (
              <div key={id} className="col-sm-12 col-md-6 col-lg-4 mb-4 ">
                <div
                  className={`plan-box card p-4 mb-4 ${
                    plan?.isPlanActive ? "bg-light-success" : "bg-light-gray"
                  }`}
                  style={{
                    boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.1)",
                    borderRadius: "10px",
                    transition: "transform 0.3s ease-in-out",
                  }}
                >
                  {/* Plan Header */}
                  <div className="text-center plan-header d-flex flex-column align-items-center">
                    {plan.icon ? (
                      <img
                        className="img-fluid mb-2"
                        src={plan?.icon}
                        alt="HomeChef360"
                        height="90px"
                        width="90px"
                      />
                    ) : null}

                    {/* Display the description below the logo */}
                    <h5 className="plan-type fs-12 text-success mb-0 fw-bold text-center mb-2">
                      {name}
                      {plan.isPlanActive && (
                        <span className="badge bg-danger ms-3">
                          {plan.is_trial ? "Trial" : ""} Active
                        </span>
                      )}
                    </h5>

                    {description && (
                      <p
                        className="fs-12 text-center mt-2"
                        style={{ maxWidth: "500px" }}
                      >
                        {description}
                      </p>
                    )}
                  </div>

                  {/* Pricing Options */}
                  <div>
                    <strong>Pricing Options:</strong>
                    {plan?.plan_details
                      // ?.filter(detail => detail.amount > 0 || detail.amount == 0 && detail.isActive)
                      ?.map((detail: PlanDetail) => (
                        <div
                          key={detail.validity}
                          className="form-check mt-3 mb-3"
                        >
                          {plan.plantype.planTypeName === "Free Plan" ? (
                            <label
                              className="form-check-label fw-bold mt-2 badge bg-danger ms-6"
                              style={{
                                padding: "0.6rem 1.0rem",
                                fontSize: "0.8rem",
                                borderRadius: "0.5rem",
                              }}
                            >
                              <span>
                                {plan.plantype.planTypeName}: $
                                {detail.amount.toFixed(2)}
                              </span>
                            </label>
                          ) : (
                            <>
                              <input
                                type="radio"
                                id={`plan-${plan.id}-${detail.validity}`}
                                name={`plan-${plan.id}`} // Unique group name for each plan
                                value={detail.amount}
                                className="form-check-input"
                                onChange={() => {
                                  handlePlanOptionChange(
                                    plan.id,
                                    detail.amount,
                                    detail.validity,
                                    detail.validityCount,
                                    detail.type,
                                    detail.isActive,
                                    detail.is_trial,
                                    detail.originalAmount
                                  );
                                }}
                                checked={(() => {
                                  const isSelectedOptionCheck =
                                    selectedPlanOption[plan.id]?.type ===
                                      detail.type &&
                                    selectedPlanOption[plan.id]?.amount ==
                                      detail.amount;
                                  return isSelectedOptionCheck;
                                })()}
                                disabled={(() => {
                                  const isDisabled =
                                    plan.isPlanActive && detail.isActive;
                                  return isDisabled;
                                })()}
                              />
                              <label
                                className={`form-check-label ${
                                  detail.amount === 0
                                    ? "text-danger fw-bold"
                                    : detail.isActive ||
                                      (plan.isPlanActive && detail.isActive)
                                    ? "text-danger-light" // Light danger text class for disabled condition
                                    : ""
                                }`}
                                htmlFor={`plan-${plan.id}-${detail.validity}`}
                                style={{
                                  fontSize: "0.9rem", // Adjust font size
                                  marginLeft: "0.5rem", // Add spacing for better alignment
                                }}
                              >
                                {(() => {
                                  const amountText = `$${detail.amount.toFixed(
                                    2
                                  )}`;
                                  const trialText =
                                    detail.amount === 0 ? " (Trial plan)" : "";

                                  return `${amountText} per ${detail.validityCount}${trialText}`;
                                })()}
                              </label>
                              {offerToday &&
                                detail.type === 1 &&
                                selectedPlanOption[plan.id]?.type === 1 && (
                                  <label className="text-success fw-bold ms-2 mt-2 mb-4">
                                    {offerText}
                                  </label>
                                )}
                            </>
                          )}
                        </div>
                      ))}
                  </div>

                  {/* Features */}
                  <div className="plan-details mt-3">
                    <strong className="text-danger">Features:</strong>
                    <ul className="list-unstyled">
                      {plan.featuresDetails.map((feature: any) => (
                        <li
                          key={feature.id}
                          className="d-flex align-items-center my-2"
                        >
                          <img
                            src="../../../assets/images/vector.png"
                            alt="tick"
                            className="me-2"
                            style={{ width: "15px", height: "15px" }}
                          />
                          <span className="me-3" style={{ fontSize: "14px" }}>
                            {feature.name}&nbsp;
                            {feature.description && (
                              <i
                                className="material-symbols-outlined info-ico me-3"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title={feature?.description}
                                style={{
                                  cursor: "pointer",
                                  position: "relative",
                                  top: "4px",
                                  fontSize: "16px",
                                  width: "20px",
                                  height: "20px",
                                }}
                                onClick={() => handleInfoClick(feature.id)}
                              >
                                info
                              </i>
                            )}
                            {activeFeatureId === feature.id && (
                              <div
                                style={{
                                  background: "#fff",
                                  padding: "10px",
                                  borderRadius: "5px",
                                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                                  border: "1px solid #ccc", // Gray border
                                  position: "absolute",
                                  zIndex: 1,
                                }}
                              >
                                {feature.description}
                              </div>
                            )}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>

                  {plan?.plantype?.planTypeName !== "Free Plan" &&
                    plan.button && (
                      <>
                        {activeMessageVisible && (
                          <p className="text-danger fw-bold mt-2 mb-2">
                            This plan is already active. Please choose a
                            different plan to upgrade.
                          </p>
                        )}

                        {plan?.isTrialUsed === false && (
                          <p className="text-danger fw-bold mt-2 mb-2">
                            Start your 30-day free trial now and explore all
                            features!
                          </p>
                        )}

                        <div className="col-lg-12 plan-footer d-flex justify-content-center align-items-center mt-0">
                          {plan?.isTrialUsed === false && (
                            <button
                              className="btn rounded-5 btn-outline-danger me-2"
                              onClick={() => {
                                if (selectedPlanOption[plan.id]) {
                                  setActivePlanDetails(plan as any);
                                  handleTrialSubscribeModal();
                                } else {
                                  toast.error(
                                    "Please select a plan option before subscribing"
                                  );
                                }
                              }}
                            >
                              Activate Trial
                            </button>
                          )}

                          {/* Only show the button if the selected plan is not active */}
                          {selectedPlanOption[plan.id]?.isActive !== true ||
                          plan.button === "Cancel" ? (
                            <button
                              className={`btn rounded-5 me-2 ${
                                subscribingPlan === plan?.id
                                  ? "btn-outline-primary" // Style while processing
                                  : buttonTexts[plan.id] === "Activate Trial"
                                  ? "btn-outline-danger" // Red for "Activate Trial"
                                  : buttonTexts[plan.id] === "Upgrade"
                                  ? "btn-outline-success" // Green for "Upgrade"
                                  : "btn-outline-secondary" // Default style
                              }`}
                              onClick={() => {
                                console.log(
                                  "Button Clicked",
                                  !selectedPlanOption[plan.id]
                                );
                                console.log(
                                  "selectedPlanOption -- button",
                                  selectedPlanOption
                                );
                                handleResetMessage(); // Reset any active message when clicking other buttons
                                if (!selectedPlanOption[plan.id]) {
                                  console.log(
                                    "entry-11",
                                    !selectedPlanOption[plan.id]
                                  );
                                  scrollToTop();
                                  toast.error(
                                    "Please select a plan option before subscribing"
                                  );
                                  return; // Prevent further actions if no plan option is selected
                                }
                                if (
                                  plan.isHigherPlan &&
                                  selectedPlanOption[plan.id]?.type === 2
                                ) {
                                  console.log(
                                    "entry-1",
                                    plan.isHigherPlan &&
                                      selectedPlanOption[plan.id]?.type === 2
                                  );
                                  scrollToTop();
                                  toast.error("You`re Already in Annual Plan");
                                  return;
                                }
                                if (plan.button === "Cancel") {
                                  handleShowUnsubscribeModal();
                                  console.log(
                                    "entry-2",
                                    plan.button === "Cancel"
                                  );
                                } else {
                                  console.log("entry-3");
                                  handleShowSubscribeModal(plan); // Proceed with the subscribe modal logic
                                }
                              }}
                              // disabled={subscribingPlan === plan?.id}
                            >
                              {subscribingPlan === plan?.id
                                ? "Processing..."
                                : buttonTexts[plan.id] || plan.button}
                            </button>
                          ) : (
                            <button
                              className="btn rounded-5 btn-outline-secondary me-2"
                              // disabled={subscribingPlan === plan?.id}
                              onClick={handleClick}
                            >
                              Active
                            </button>
                          )}
                        </div>
                      </>
                    )}
                </div>
              </div>
            );
          })
        )}
      </div>

      <Footer />
      {/* Confirmation of wallet or payment using card to proceed payment */}

      <Modal
        show={showSubscribeConformationModal}
        onHide={() => setShowSubscribeConformationModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Choose Your Payment Method</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="text-center text-danger mt-3">
            Select a payment method to proceed: $
            {offer ? (
              <>
                <span style={{ textDecoration: "line-through", color: "gray" }}>
              {selectedPlanOption[
                    activePlanDetails?.id || ""
                  ]?.amount?.toFixed(2)}
                </span>{" "}
                <span className="fw-bold">${offerAmount}</span>{" "}
              </>
            ) : (
              (
                selectedPlanOption[activePlanDetails?.id || ""]?.amount || 0
              ).toFixed(2)
            )}
          </p>

          <div className="row mt-3">
            {/* Wallet Balance Option */}
            <div className="col-12 col-md-6 mb-3">
              <div className="form-check d-flex align-items-center">
                <label className="form-check-label d-flex align-items-center w-100">
                  <input
                    className="form-check-input me-3"
                    type="radio"
                    name="paymentType"
                    value="1" // Wallet Balance
                    checked={paymentType === 1}
                    onChange={() => setPaymentType(1)}
                  />
                  <div className="d-flex flex-column">
                    <span className="d-flex align-items-center fw-bold">
                      <span
                        className="material-symbols-outlined me-2"
                        style={{
                          color: paymentType === 1 ? "#007bff" : "gray",
                        }}
                      >
                        account_balance_wallet
                      </span>
                      Wallet Balance
                    </span>
                    <small className="text-muted">
                      Use your available balance ($
                      {walletBalance ? walletBalance.toFixed(2) : "0.00"})
                    </small>
                  </div>
                </label>
              </div>

              {/* Wallet Amount Input - Only show if wallet is selected */}
              {paymentType === 1 && (
                <div className="mt-3 mb-2">
                  <label>Enter Amount to Deduct:</label>
                  <input
                    type="number"
                    className="form-control"
                    value={walletInputAmount}
                    onChange={(e) => {
                      const value = Number(e.target.value);
                      const selectedPlanAmount =
                        selectedPlanOption[activePlanDetails?.id || ""]
                          ?.amount || 0;

                      // Ensure wallet input does not exceed wallet balance or selected plan amount
                      if (value > selectedPlanAmount) {
                        setWalletInputAmount(selectedPlanAmount);
                      } else if (value > walletBalance) {
                        setWalletInputAmount(walletBalance);
                      } else {
                        setWalletInputAmount(value);
                      }
                    }}
                    placeholder={`Enter up to ${Math.min(
                      walletBalance,
                      selectedPlanOption[activePlanDetails?.id || ""]?.amount ||
                        0
                    ).toFixed(2)}`}
                  />
                </div>
              )}
            </div>

            {/* Pay by Card Option */}
            <div className="col-12 col-md-6 mb-3">
              <div className="form-check d-flex align-items-center">
                <label className="form-check-label d-flex align-items-center w-100">
                  <input
                    className="form-check-input me-3"
                    type="radio"
                    name="paymentType"
                    value="2" // Pay by Card
                    checked={paymentType === 2}
                    onChange={() => setPaymentType(2)}
                  />
                  <div className="d-flex flex-column">
                    <span className="d-flex align-items-center fw-bold">
                      <span
                        className="material-symbols-outlined me-2"
                        style={{
                          color: paymentType === 2 ? "#007bff" : "gray",
                        }}
                      >
                        credit_card
                      </span>
                      Pay by Card
                    </span>
                    <small className="text-muted">
                      Pay using your credit/debit card
                    </small>
                  </div>
                </label>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer className="d-flex justify-content-center">
          <button
            className="btn btn-cancel rounded-5 px-5 mt-2 mb-2"
            onClick={() => setShowSubscribeConformationModal(false)}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary rounded-5 px-5 mt-2 mb-2"
            onClick={() => handleSubscribe(activePlanDetails.id)}
            disabled={
              paymentType === 1
                ? walletInputAmount <= 0 ||
                  walletInputAmount >
                    (selectedPlanOption[activePlanDetails?.id || ""]?.amount ||
                      0) ||
                  walletInputAmount > walletBalance
                : paymentType !== 2 // Disable for no payment type selected
            }
          >
            Confirm{" "}
            {walletInputAmount > 0 ? "Payment" : activePlanDetails?.button}
          </button>
        </Modal.Footer>
      </Modal>

      {/*Trial Plan Subscribe Modal */}
      <Modal
        show={showTrialSubscribeModal}
        onHide={handleTrialSubscribeModal}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Activate Trial Plan to {activePlanDetails?.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="card mb-3">
            <div className="card-header text-center text-warning">
              <span role="img" aria-label="alert" style={{ fontSize: "24px" }}>
                ⚠️
              </span>{" "}
              Trial Plan Information
            </div>
            <div className="card-body text-center">
              <h5 className="card-title text-success fw-bold">
                Trial Plan Activation
              </h5>
              <p>
                This plan is a <strong>trial</strong>. You can only activate a
                trial plan once. Please proceed if you want to use your trial
                for this plan:
              </p>
              <h4 className="text-center text-danger">
                {activePlanDetails?.name}
              </h4>
              <p>
                Validity :{" "}
                {selectedPlanOption[activePlanDetails?.id || ""]
                  ?.validityCount == "Month"
                  ? "Monthly"
                  : selectedPlanOption[activePlanDetails?.id || ""]
                      ?.validityCount == "Year"
                  ? "Annual"
                  : ""}
              </p>
            </div>
            {/* <CardInput/> */}
          </div>
        </Modal.Body>

        <Modal.Footer className="d-flex justify-content-center">
          <button
            className="btn btn-cancel rounded-5 px-5 mt-2 mb-2"
            onClick={handleTrialSubscribeModal}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary rounded-5 px-5 mt-2 mb-2"
            onClick={() => {
              handleTrialSubscription(activePlanDetails.id);
            }}
          >
            Confirm Trial Activation
          </button>
        </Modal.Footer>
      </Modal>

      {/* Subscribe Modal */}
      <Modal
        show={showSubscribeModal}
        onHide={handleCloseSubscribeModal}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {activePlanDetails?.button === "upgrade"
              ? "Upgrade"
              : activePlanDetails?.button === "downgrade"
              ? "Downgrade"
              : "Subscribe"}{" "}
            to {activePlanDetails?.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {subscribedActivePlan && activePlanDetails?.button === "Upgrade" ? (
            <>
              {/* Upgrade Card */}
              <div className="card mb-3">
                <div className="card-header text-center text-warning">
                  <span
                    role="img"
                    aria-label="alert"
                    style={{ fontSize: "24px" }}
                  >
                    ⚠️
                  </span>{" "}
                  Active Plan Information
                </div>
                <div className="card-body text-center">
                  <h5 className="card-title text-success fw-bold">
                    You already have an active plan:
                  </h5>
                  <p className="card-text">
                    <strong className="text-danger">
                      {subscribedActivePlan?.name} {""}{" "}
                      {subscribedActivePlan?.is_trial === true &&
                        " (Trial plan)"}
                    </strong>
                  </p>
                  <p>
                    {subscribedActivePlan.plan_details.map((plan: any) =>
                      plan.isActive ? (
                        <span key={plan.id} className="text-success">
                          ${plan?.amount ? plan.amount.toFixed(2) : "0.00"} for{" "}
                          {plan?.validityCount}
                        </span>
                      ) : null
                    )}
                  </p>
                </div>
              </div>

              {/* Upgrade Confirmation */}
              <p>Are you sure you want to upgrade to the plan?</p>
              <h4 className="text-center text-danger">
                {activePlanDetails?.name}
              </h4>
              <p>
                Validity:{" "}
                {offerToday &&
                offerAmount &&
                selectedPlanOption[activePlanDetails?.id || ""]?.type == 1 ? (
                  <>
                    <span
                      style={{ textDecoration: "line-through", color: "gray" }}
                    >
                      $
                      {selectedPlanOption[
                        activePlanDetails?.id || ""
                      ]?.amount?.toFixed(2)}
                    </span>{" "}
                    <span className="text-success fw-bold">${offerAmount}</span>{" "}
                  </>
                ) : (
                  <span className="me-1">
                    $
                    {selectedPlanOption[
                      activePlanDetails?.id || ""
                    ]?.amount?.toFixed(2)}
                  </span>
                )}
                per{" "}
                {selectedPlanOption[activePlanDetails?.id || ""]?.validityCount}
              </p>
            </>
          ) : subscribedActivePlan &&
            activePlanDetails?.button === "Downgrade" ? (
            <>
              {/* Downgrade Card */}
              <div className="card mb-3">
                <div className="card-header text-center text-warning">
                  <span
                    role="img"
                    aria-label="alert"
                    style={{ fontSize: "24px" }}
                  >
                    ⚠️
                  </span>{" "}
                  Active Plan Information
                </div>
                <div className="card-body text-center">
                  <h5 className="card-title text-success fw-bold">
                    You already have an active plan:
                  </h5>
                  <p className="card-text">
                    <strong className="text-danger">
                      {subscribedActivePlan?.name}
                      {""}{" "}
                      {subscribedActivePlan?.is_trial === true &&
                        " (Trial plan)"}
                    </strong>
                  </p>
                  <p>
                    {subscribedActivePlan.plan_details.map((plan: any) =>
                      plan.isActive ? (
                        <span key={plan.id} className="text-success">
                          ${plan?.amount ? plan.amount.toFixed(2) : "0.00"} for{" "}
                          {plan?.validityCount}
                        </span>
                      ) : null
                    )}
                  </p>
                </div>
              </div>

              {/* Downgrade Confirmation */}
              <p>Are you sure you want to downgrade to the plan:</p>
              <h4 className="text-center text-danger">
                {activePlanDetails?.name}
              </h4>
              <p>
                Validity: $
                {selectedPlanOption[
                  activePlanDetails?.id || ""
                ]?.amount.toFixed(2)}{" "}
                for{" "}
                {selectedPlanOption[activePlanDetails?.id || ""]?.validityCount}
              </p>
            </>
          ) : (
            <>
              {/* Subscription Information */}
              <p>Are you sure you want to subscribe to the plan:</p>
              <h4 className="text-center text-danger">
                {activePlanDetails?.name}
              </h4>
              <p>
                Validity: $
                {selectedPlanOption[
                  activePlanDetails?.id || ""
                ]?.amount.toFixed(2)}{" "}
                for{" "}
                {selectedPlanOption[activePlanDetails?.id || ""]?.validityCount}
              </p>
            </>
          )}
        </Modal.Body>

        <Modal.Footer className="d-flex justify-content-center">
          <button
            className="btn btn-cancel rounded-5 px-5 mt-2 mb-2"
            onClick={handleCloseSubscribeModal}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary rounded-5 px-5 mt-2 mb-2"
            onClick={() => {
              if (
                walletBalance > 1 &&
                selectedPlanOption[activePlanDetails?.id || ""]?.is_trial ==
                  false
              ) {
                // Logic for trial activation
                console.log(
                  " handleCloseSubscribeModal(); ",
                  walletBalance > 1
                );
                handleCloseSubscribeModal();
              } else if (walletBalance > 1) {
                // Logic for trial activation
                console.log(
                  " handleCloseSubscribeModal(); ",
                  walletBalance > 1
                );
                handleCloseSubscribeModal();
              } else {
                handleSubscribe(activePlanDetails.id); // Regular subscription
              }
            }}
          >
            Confirm{" "}
            {activePlanDetails?.button === "Upgrade"
              ? "Upgrade"
              : activePlanDetails?.button === "Downgrade"
              ? "Downgrade"
              : "Subscription"}
          </button>
        </Modal.Footer>
      </Modal>
      {showUnsubscribeModal && (
        <PlanUnsubscribe showFinalModal={showUnsubscribeModal} />
      )}
    </section>
  );
};

export default ListPlans;
