import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { getPaymentStatusChef } from "../core/_requests";

const UserPromoteKitSuccess = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [result, setResult] = useState<{
    message: string;
    status: boolean;
    data: any;
  } | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const checkPaymentStatus = async (session_id: string) => {
    if (!session_id) return;
    setLoading(true);  // Set loading to true when starting request

    try {
      console.log("Checking payment status for sessionId:", session_id);
      const res = await getPaymentStatusChef(session_id);
      console.log("Payment status response:", res);
      setResult(res);

      if (res.status === true) {
        // toast.success(res?.message, { autoClose: 5000 });
      } else {
        toast.error(res?.message, { autoClose: 5000 });
      }
    } catch (error: any) {
      console.error("Error checking payment status:", error);
      toast.error("Something went wrong. Please try again.");
    } finally {
      localStorage.removeItem("sessionId");
      setLoading(false);  // Stop loading after request completes
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const session_id = searchParams.get("session_id");
    if (session_id) {
      checkPaymentStatus(session_id as any);
    } else {
      setLoading(false);  // If no session_id, stop loading
    }
  }, [location.search]);

  const { message, status, data } = result || {};
  const affiliateTracking = data?.affiliateTracking;
  const customerName = data?.customerName;
  const customerEmail = data?.customerEmail;
  const alreadyActiveChef = data?.alreadyActiveChef;
  
  const redirectUrl = process.env.REACT_APP_CHEF_PORTAL;

  const getRedirectUrl = () => {
    if (alreadyActiveChef) {
      return `${redirectUrl}login`; // Redirect to login page
    } else {
      return `${redirectUrl}auth`; // Redirect to signup page
    }
  };

  const androidUrl = process.env.REACT_APP_USER_PORTAL_ANDROID;
  const iosUrl = process.env.REACT_APP_USER_PORTAL_IOS;

  return (
    <section className="container-fluid">
      <div className="overlay"></div>
      <div className="drawer small add-dish">
        <div className="alert-wrap">
          <img
            className="ico mb-4"
            src="../assets/images/alert-success-ico.svg"
            alt="success"
          />
          <h1 className="mb-4">Awesome!</h1>
          <p className="mid-gray mb-2">
            <small>{message || "Payment Successful, please login to Dashboard"}</small>
          </p>

          {customerName && customerEmail && (
            <div className="customer-info">
              <p><strong>Name:</strong> {customerName}</p>
              <p><strong>Email:</strong> {customerEmail}</p>
            </div>
          )}

          {affiliateTracking && (
            <div className="affiliate-info">
              <p className="d-none"><strong>Affiliate Plan:</strong> {affiliateTracking.plan_id}</p>
              <p><strong>Amount:</strong> ₹{affiliateTracking.amount}</p>
              <p><strong>Tracking Status:</strong> {affiliateTracking.status}</p>
            </div>
          )}

          {alreadyActiveChef ? (
            <p className="mid-gray mb-2">
              You are already a HomeChef360 chef, please login to access your dashboard.
            </p>
          ) : (
            <p className="mid-gray mb-2">
              You are not a HomeChef360 chef yet, please sign up to enjoy our plan benefits.
            </p>
          )}

          <div className="mt-4">
            {/* Android Icon */}
            {androidUrl && (
              <a href={androidUrl} target="_blank" rel="noopener noreferrer">
                <img
                  className="store-icon"
                  src="../assets/images/play-store-ico.png"
                  alt="Download on Google Play"
                  style={{ width: "100px", marginRight: "10px" }}
                />
              </a>
            )}

            {/* iOS Icon */}
            {iosUrl && (
              <a href={iosUrl} target="_blank" rel="noopener noreferrer">
                <img
                  className="store-icon"
                  src="../assets/images/app-store-ico.png"
                  alt="Download on the App Store"
                  style={{ width: "100px" }}
                />
              </a>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default UserPromoteKitSuccess;
