import React, { useEffect } from "react";

const PromoteKitScript: React.FC = () => {
  useEffect(() => {
    console.log("=== PromoteKitScript Component Mounted ===");

    // Check if the script is already loaded
    const existingScript = document.querySelector("script[src='https://cdn.promotekit.com/promotekit.js']");
    console.log("Existing script check:", existingScript);

    if (!existingScript) {
      console.log("No existing script found. Creating new script element...");

      // Create a new script element
      const script = document.createElement("script");
      script.src = "https://cdn.promotekit.com/promotekit.js";
      console.log("Script source set to:", script.src);

      script.async = true;
      console.log("Script async attribute set to:", script.async);

      // script.setAttribute("data-promotekit", "ffcd0481-870b-40aa-b8ea-13272791896d");
      script.setAttribute("data-promotekit", "845b390a-cee6-47d9-a8c1-46331b5fa3d5");
      console.log("Script data-promotekit attribute set to:", script.getAttribute("data-promotekit"));

      // Listen for the script load event
      script.onload = () => {
        console.log("PromoteKit script loaded successfully.");
        // You can check for the window.promotekit_refferal here if needed
        if (window.promotekit_refferal) {
          console.log("Referral data: window.promotekit_refferal", window.promotekit_refferal);
        }
      };

      // Listen for script errors
      script.onerror = (error) => {
        console.error("Error loading PromoteKit script:", error);
      };

      // Append the script to the body
      document.body.appendChild(script);
      console.log("Script appended to the body:", script);
    } else {
      console.log("Existing script already present. No new script created.");
    }

    // Cleanup the script when the component unmounts
    return () => {
      console.log("=== PromoteKitScript Component Unmounted ===");
      const existingScript = document.querySelector("script[src='https://cdn.promotekit.com/promotekit.js']");
      // Ensure script is removed only if it's still in the document
      if (existingScript && document.body.contains(existingScript)) {
        document.body.removeChild(existingScript);
        console.log("Script removed from the body.");
      } else {
        console.log("Script not found or already removed.");
      }
    };
  }, []);  // Empty dependency array to run the effect only once

  console.log("PromoteKitScript component rendered.");
  return null; // No UI rendering
};

export default PromoteKitScript;
