import React, { useEffect, useState } from "react";
import { createPromoteKitCheckoutSessionUser, getPlanForAffiliateUser } from "../core/_requests";
import { useNavigate, useLocation } from "react-router-dom";
import { Plan, PlanDetail } from "../core/_models";
import { toast } from "react-toastify";
import SocialMediaIcons from "./SocialMedia";

const SalesPageUserPromoteKit = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [plans, setPlans] = useState<Plan[]>([]);
  const [subscribedActivePlan, setSubscribedActivePlan] = useState<Plan | null>(null);
  const [subscribingPlan, setSubscribingPlan] = useState<string | null>(null);
  const [selectedPlanOption, setSelectedPlanOption] = useState<{ [key: string]: PlanDetail | null }>({});
  const [activeFeatureId, setActiveFeatureId] = useState<number | null>(null);

  const handleInfoClick = (id: number) => {
    setActiveFeatureId((prevId) => (prevId === id ? null : id)); // Toggle the tooltip
  };
  const getPlanIdFromURL = () => {
    const urlParamsString = location.search.split('?')[1]; // Get the part after `?`

    if (!urlParamsString) return { planId: null, via: null };

    const urlParamsArray = urlParamsString.split('&'); // Split by '&' for multiple parameters

    // Define the params object with an explicit type
    const params: { [key: string]: string } = {};

    urlParamsArray.forEach(param => {
      const [key, value] = param.split('=');
      if (key && value) {
        params[key] = value;
      }
    });

    // Return the values with explicit typing
    return {
      planId: params['planId'] || null,
      via: params['via'] || null
    };
  };
  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (activeFeatureId !== null) {
      // Set a timeout to hide the tooltip after 10 seconds
      timer = setTimeout(() => {
        setActiveFeatureId(null);
      }, 10000); // 10 seconds
    }

    // Cleanup the timeout if the component unmounts or activeFeatureId changes
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [activeFeatureId]);

  const getActiveDetail = (plan: Plan) =>
    plan.plan_details.find(detail => detail.isActive) || plan.plan_details[0];

  const fetchPlans = async () => {
    try {
      const res = await getPlanForAffiliateUser();
      const plansData: Plan[] = res?.data || [];
      setPlans(plansData);

      const activePlans = plansData.filter((plan: Plan) => plan.isPlanActive); // Filter active plans
      const initialSelectedOptions: { [key: string]: PlanDetail | null } = {};

      activePlans.forEach((activePlan: Plan) => {
        setSubscribedActivePlan(activePlan);

        // Attempt to find the active detail first
        const activeDetail = activePlan.plan_details.find((detail: PlanDetail) => detail.isActive);

        const selectedDetail = getActiveDetail(activePlan) || activePlan.plan_details[0];
        if (selectedDetail) {
          initialSelectedOptions[activePlan.id] = {
            amount: selectedDetail.amount,
            originalAmount: selectedDetail.originalAmount,
            validity: selectedDetail.validity,
            validityCount: selectedDetail.validityCount,
            type: selectedDetail.type,
            isActive: selectedDetail.isActive,
            is_trial: selectedDetail.is_trial,
          };
        }
      });

      setSelectedPlanOption(initialSelectedOptions);
    } catch (error) {
      console.error("Error fetching plans:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPlans();
  }, []);

  const handlePlanOptionChange = (
    planId: string,
    amount: number,
    validity: number,
    validityCount: string,
    type: any,
    isActive: boolean,
    is_trial: boolean,
    originalAmount: number
  ) => {
    setSelectedPlanOption((prevState) => {
      const newState = { ...prevState };
      // Uncheck all other plans first, only if they are not active
      Object.keys(newState).forEach((key) => {
        if (key !== planId && newState[key]?.type === 1) {
          newState[key] = null; // Uncheck other plans that are not active
        }
      });
      newState[planId] = { amount, validityCount, validity, type, isActive, is_trial, originalAmount };
      return newState;
    });
  };

  const handleCheckout = async (planId: string) => {
    console.log("=== Initiating Checkout ===");
    console.log("Plan ID:", planId);
  
    const selectedOption = selectedPlanOption[planId];
    console.log("Selected Plan Option:", selectedOption);
  
    if (!selectedOption) {
      console.error("No plan option selected. Aborting checkout.");
      toast.error("Please select a plan option before subscribing");
      return;
    }
  
    const { amount, validity, type, is_trial, originalAmount } = selectedOption;
    console.log("Extracted Plan Details:", {
      amount,
      validity,
      type,
      is_trial,
      originalAmount,
    });
  
    setSubscribingPlan(planId);
    console.log("Subscribing plan state updated to:", planId);
  
    setLoading(true);
    console.log("Loading state set to true.");
  
    try {
      const urlParams = new URLSearchParams(window.location.search);
      console.log("urlParams userr",urlParams)
      const promotekitReferral = urlParams.get("promotekit_referral") || (window as any).promotekit_referral || "845b390a-cee6-47d9-a8c1-46331b5fa3d5" ;
      console.log("Retrieved promotekit referral:", promotekitReferral);
  
      if (!promotekitReferral) {
        console.error("Promotekit referral is not defined.");
        alert("Referral information is missing. Please try again.");
        setLoading(false);
        console.log("Loading state set to false due to missing referral.");
        return;
      }
  
      // Prepare checkout data
      const data = {
        referral: promotekitReferral,
        amount: amount || 0,
        planId,
        originalAmount: originalAmount || 0,
        type: type || "",
        validity: validity || 0,
      };
      console.log("Constructed Checkout Data:", data);
  
      // Call API to create a checkout session for the plan
      console.log("Calling createPromoteKitCheckoutSessionUser API...");
      const response = await createPromoteKitCheckoutSessionUser(data);
      console.log("API Response:", response);
  
      if (response.status === true) {
        console.log("API returned success. Redirecting to:", response.url);
        toast.success(response?.message);
        window.location.href = response.url || window.location.assign(response.url);
      } else {
        console.error("Checkout session creation failed. Response:", response);
        alert("Failed to proceed to checkout. Please try again.");
      }
    } catch (err) {
      console.error("Error creating checkout session:", err);
      toast.error("An error occurred while processing your payment.");
    } finally {
      setLoading(false);
      setSubscribingPlan(null);
      console.log("Reset loading and subscribing plan states.");
    }
    console.log("=== Checkout Flow Completed ===");
  };
  

  return (
    <section className="container-fluid">
    <section className="head row top-header-sales-page align-items-center">
      {/* Logo and Welcome Text Section */}
      <div className="col-12 col-md-8 d-flex align-items-center">
        {/* Logo */}
        <div className="col-3 col-md-2 d-flex align-items-center justify-content-center">
          <img
            className="img-fluid"
             src="../../assets/images/user-portal-logo.svg"
            alt="Logo user"
            style={{ height: "100px" }}
          />
        </div>

        {/* Welcome Text */}
        <div className="col-9 col-md-10 d-flex align-items-center justify-content-end">
          <h5 className="fs-5 fs-md-3 fw-bold text-success mb-0 text-center">
            Welcome to HomeChef360 Subscription
          </h5>
        </div>

      </div>

      {/* Social Media Icons Section */}
      <div className="col-12 col-md-4 d-flex align-items-center justify-content-end">
        <SocialMediaIcons />
      </div>
    </section>

    <div className="plan-container justify-content-center mb-2">
      <div className="col-lg-8 col-md-8 col-sm-12 offset-lg-2 offset-md-2 text-justify mx-3">
        <p className="text-muted">
          HomeChef360 connects food lovers with talented chefs, offering unique dining experiences and personalized meals.
        </p>
      </div>

      {loading ? (
        <div className="col-12 text-center">Loading...</div>
      ) : plans?.length > 0 ? (
        plans
          // ?.filter((plan: any) => subscribedActivePlan?.id === plan.id) // Filter active plans
          .map((plan: any) => (
            <div key={plan?.id} className="col-lg-4 col-md-6 col-sm-12">
              <div className="plan-box-sales-page card p-4 mb-4">
                <div className="plan-header d-flex flex-column align-items-center">
                  {plan.icon ? (
                    <img
                      className="img-fluid mb-2"
                      src={plan?.icon}
                      alt="HomeChef360"
                      height="90px"
                      width="90px"
                    />
                  ) : null}
                  <h5 className="plan-type fs-12 text-success mb-0 fw-bold text-center mb-2">
                    {plan.name}
                  </h5>
                  {plan.description && (
                    <p className="fs-12 justify-content-center text-justify mt-2" style={{ maxWidth: "500px" }}>
                      {plan.description} </p>
                  )}
                </div>

                {/* Pricing Options */}
                <div>
                  <strong>Pricing Options:</strong>
                  {plan?.plan_details
                    ?.filter((detail: any) => detail.type === 1)
                    ?.map((detail: PlanDetail) => (
                      <div key={detail.validity} className="form-check mt-3 mb-3 ">

                        <>
                          <input
                            type="radio"
                            id={`plan-${plan.id}-${detail.validity}`}
                            name={`plan-${plan.id}`} // Unique group name for each plan
                            value={detail.amount}
                            className="form-check-input"
                            onChange={() => {

                              handlePlanOptionChange(
                                plan.id,
                                detail.amount,
                                detail.validity,
                                detail.validityCount,
                                detail.type,
                                detail.isActive,
                                detail.is_trial,
                                detail.originalAmount,
                              );
                            }}
                            checked={(() => {
                              const isPlanActiveCheck =
                                plan.isPlanActive && detail.isActive && plan.is_trial === detail.is_trial;
                              const isSelectedOptionCheck =
                                selectedPlanOption[plan.id]?.type === detail.type &&
                                selectedPlanOption[plan.id]?.amount == detail.amount;


                              return isPlanActiveCheck || isSelectedOptionCheck;
                            })()}
                            disabled={(() => {
                              const isDisabled = plan.isPlanActive && detail.isActive;

                              return isDisabled;
                            })()}
                          />
                          <label
                            className={`form-check-label ${detail.amount === 0
                              ? "text-danger fw-bold"
                              : detail.isActive || (plan.isPlanActive && detail.isActive)
                                ? "text-danger-light" // Light danger text class for disabled condition
                                : ""
                              }`}
                            htmlFor={`plan-${plan.id}-${detail.validity}`}
                            style={{
                              fontSize: "0.9rem", // Adjust font size
                              marginLeft: "0.5rem", // Add spacing for better alignment
                            }}
                          >
                            {(() => {
                              const amountText = `$${detail.amount.toFixed(2)}`;
                              const trialText = detail.amount === 0 ? " (Trial plan)" : "";

                              return `${amountText} for ${detail.validity}${trialText}`;
                            })()}
                          </label>

                        </>

                      </div>
                    ))}
                </div>
                <div className="plan-details mt-3 mb-2 mt-3">
                  <strong className="text-danger">Features:</strong>
                  <ul className="list-unstyled">
                    {plan.featuresDetails
                      .map((feature: any) => (
                        <li key={feature.id} className="d-flex align-items-center my-2">
                          <img
                            src="../../../assets/images/vector.png"
                            alt="tick"
                            className="me-2"
                            style={{ width: "15px", height: "15px" }}
                          />
                          <span className="me-3" style={{ fontSize: "14px" }}>
                            {feature.name}&nbsp;
                            {feature.description && (
                              <i
                                className="material-symbols-outlined info-ico me-3"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title={feature?.description}
                                style={{
                                  cursor: "pointer",
                                  position: "relative",
                                  top: "4px",
                                  fontSize: "16px",
                                  width: "20px",
                                  height: "20px",
                                }}
                                onClick={() => handleInfoClick(feature.id)}
                              >
                                info
                              </i>
                            )}
                            {activeFeatureId === feature.id && (
                              <div
                                style={{
                                  background: "#fff",
                                  padding: "10px",
                                  borderRadius: "5px",
                                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                                  border: "1px solid #ccc", // Gray border
                                  position: "absolute",
                                  zIndex: 1,
                                }}
                              >
                                {feature.description}
                              </div>
                            )}
                          </span>
                        </li>
                      ))}
                  </ul>
                </div>

                {/* {subscribedActivePlan?.id === plan.id && ( */}
                <div className="col-lg-12 plan-footer d-flex justify-content-center align-items-center mt-3 mb-2">
                  <button
                    className="btn rounded-5 me-2 btn-outline-success"
                    onClick={() => handleCheckout(plan.id)}
                    disabled={loading}
                  >
                    {subscribingPlan === plan.id ? "Subscribing..." : "Buy Now"}
                  </button>
                </div>
                {/* )} */}

              </div>
            </div>
          ))
      ) : (
        <div className="col-12 text-center">No plans available.</div>
      )}
    </div>
  </section>
  );
};

export default SalesPageUserPromoteKit;
