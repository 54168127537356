import React, { useEffect, useState } from "react";
import { createPromoteKitCheckoutSessionChef, getPlanForAffiliateChef } from "../core/_requests";
import { useNavigate, useLocation } from "react-router-dom";
import { Plan, PlanDetail } from "../core/_models";
import SocialMediaIcons from "./SocialMedia";
import { toast } from "react-toastify";

const SalesPageChefsPromoteKit = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [plans, setPlans] = useState<Plan[]>([]);
  const [subscribedActivePlan, setSubscribedActivePlan] = useState<Plan | null>(null);
  const [subscribingPlan, setSubscribingPlan] = useState<string | null>(null);
  const [selectedPlanOption, setSelectedPlanOption] = useState<{ [key: string]: PlanDetail | null }>({});
  const [activeFeatureId, setActiveFeatureId] = useState<number | null>(null);

  const getPlanIdFromURL = () => {
    const urlParamsString = location.search.split('?')[1]; // Get the part after `?`

    if (!urlParamsString) return { planId: null, via: null };

    const urlParamsArray = urlParamsString.split('&'); // Split by '&' for multiple parameters

    // Define the params object with an explicit type
    const params: { [key: string]: string } = {};

    urlParamsArray.forEach(param => {
      const [key, value] = param.split('=');
      if (key && value) {
        params[key] = value;
      }
    });

    // Return the values with explicit typing
    return {
      planId: params['planId'] || null,
      via: params['via'] || null
    };
  };


  
  const handleInfoClick = (id: number) => {
      setActiveFeatureId((prevId) => (prevId === id ? null : id)); // Toggle the tooltip
    };
    useEffect(() => {
      let timer: NodeJS.Timeout | null = null;
  
      if (activeFeatureId !== null) {
        // Set a timeout to hide the tooltip after 10 seconds
        timer = setTimeout(() => {
          setActiveFeatureId(null);
        }, 10000); // 10 seconds
      }
  
      // Cleanup the timeout if the component unmounts or activeFeatureId changes
      return () => {
        if (timer) {
          clearTimeout(timer);
        }
      };
    }, [activeFeatureId]);
  const getActiveDetail = (plan: Plan) =>
    plan.plan_details.find(detail => detail.isActive) || plan.plan_details[0];

  const fetchPlans = async () => {
    try {
      const res = await getPlanForAffiliateChef();
      const plansData: Plan[] = res?.data || [];
      setPlans(plansData);

      const activePlans = plansData.filter((plan: Plan) => plan.isPlanActive); // Filter active plans
      const initialSelectedOptions: { [key: string]: PlanDetail | null } = {};

      activePlans.forEach((activePlan: Plan) => {
        setSubscribedActivePlan(activePlan);

        // Attempt to find the active detail first
        const activeDetail = activePlan.plan_details.find((detail: PlanDetail) => detail.isActive);
        const selectedDetail = getActiveDetail(activePlan) || activePlan.plan_details[0];

        if (selectedDetail) {
          initialSelectedOptions[activePlan.id] = {
            amount: selectedDetail.amount,
            originalAmount: selectedDetail.originalAmount,
            validity: selectedDetail.validity,
            validityCount: selectedDetail.validityCount,
            type: selectedDetail.type,
            isActive: selectedDetail.isActive,
            is_trial: selectedDetail.is_trial,
          };
        }
      });

      setSelectedPlanOption(initialSelectedOptions);
    } catch (error) {
      console.error("Error fetching plans:", error);
    } finally {
      setLoading(false);
    }
  };

  
  useEffect(() => {
    fetchPlans();
  }, []);

  const handlePlanOptionChange = (
    planId: string,
    amount: number,
    validity: number,
    validityCount: string,
    type: any,
    isActive: boolean,
    is_trial: boolean,
    originalAmount: number
  ) => {
    setSelectedPlanOption((prevState) => {
      const newState = { ...prevState };
  
      // Uncheck all other plans first, only if they are not active
      Object.keys(newState).forEach((key) => {
        if (key !== planId && newState[key]?.type === 1) {
          newState[key] = null; // Uncheck other plans that are not active
        }
      });

      newState[planId] = { amount, validityCount, validity, type, isActive, is_trial, originalAmount };
      return newState;
    });
  };

  const handleCheckout = async (planId: string) => {
    console.log("Initiating checkout for plan:", planId);
  
    const selectedOption = selectedPlanOption[planId];
    console.log("Selected option for plan:", selectedOption);
  
    if (!selectedOption) {
      console.error("No plan option selected. Aborting checkout.");
      toast.error("Please select a plan option before subscribing");
      return;
    }
  
    const { amount, validity, type, is_trial, originalAmount } = selectedOption;
    console.log("Extracted plan details:", { amount, validity, type, is_trial, originalAmount });
  
    setSubscribingPlan(planId);
    setLoading(true);
    console.log("Set subscribing plan and loading state.");
  
    try {
      const urlParams = new URLSearchParams(window.location.search);
      console.log("urlParams chef",urlParams)
      const promotekitReferral =
        urlParams.get("promotekit_referral") || (window as any).promotekit_referral || "845b390a-cee6-47d9-a8c1-46331b5fa3d5";
      console.log("Retrieved promotekit referral:", promotekitReferral);
  
      if (!promotekitReferral) {
        console.error("Promotekit referral is not defined.");
        alert("Referral information is missing. Please try again.");
        setLoading(false);
        return;
      }
  
      const data = {
        referral: promotekitReferral,
        amount: amount || 0,
        planId,
        originalAmount: originalAmount || 0,
        type: type || "",
        validity: validity || 0,
      };
      console.log("Constructed data for checkout session:", data);
  
      const response = await createPromoteKitCheckoutSessionChef(data);
      console.log("Response from createPromoteKitCheckoutSessionChef:", response);
  
      if (response.status === true) {
         // Set sessionId in local storage
      const sessionId = response.sessionId; // Ensure this key matches the API response
      if (sessionId) {
        localStorage.setItem("checkoutSessionId", sessionId);
        console.log("Session ID stored in local storage:", sessionId);
      } else {
        console.warn("Session ID is missing in the response.");
      }
        console.log("Checkout session created successfully. Redirecting to URL:", response.url);
        toast.success(response?.message);
        window.location.href = response.url || window.location.assign(response.url);
      } else {
        console.error("No URL returned or response status is false:", response);
        alert("Failed to proceed to checkout. Please try again.");
      }
    } catch (err) {
      console.error("Error creating checkout session:", err);
    } finally {
      setLoading(false);
      setSubscribingPlan(null);
      console.log("Reset loading and subscribing plan states.");
    }
  };
  
  return (
    <section className="container-fluid">

      <section className="head row top-header-sales-page align-items-center">
        {/* Logo and Welcome Text Section */}
        <div className="col-12 col-md-8 d-flex align-items-center">
          {/* Logo */}
          <div className="col-3 col-md-2 d-flex align-items-center justify-content-center">
            <img
              className="img-fluid"
              src="../../assets/images/homecheflogo.svg"
              alt="Logo"
              style={{ height: "100px" }}
            />
          </div>

          {/* Welcome Text */}
          <div className="col-9 col-md-10 d-flex align-items-center justify-content-end">
            <h5 className="fs-5 fs-md-3 fw-bold text-success mb-0 text-center">
              Welcome to HomeChef360 – Empowering Chefs Everywhere!
            </h5>
          </div>

        </div>

        {/* Social Media Icons Section */}
        <div className="col-12 col-md-4 d-flex align-items-center justify-content-end">
          <SocialMediaIcons />
        </div>
      </section>



      <div className="plan-container justify-content-center mb-2">
        <div className="col-lg-8 col-md-8 col-sm-12 offset-lg-2 offset-md-2 text-justify mx-3">
          <p className="text-muted">
            HomeChef360 is a dynamic platform designed to connect talented chefs with food lovers.
            Whether you're just starting out or looking to scale your culinary business,
            HomeChef360 provides all the tools and support you need to succeed in the competitive food industry.
          </p>
        </div>



        {loading ? (
          <div className="col-12 text-center">Loading...</div>
        ) : plans?.length > 0 ? (
          plans
            // ?.filter((plan: any) => subscribedActivePlan?.id === plan.id) // Filter active plans
            .map((plan: any) => (
              <div key={plan?.id} className="col-lg-4 col-md-6 col-sm-12">
                <div className="plan-box-sales-page card p-4 mb-4 me-5">
                  <div className="plan-header d-flex flex-column align-items-center">
                    {plan.icon ? (
                      <img
                        className="img-fluid mb-2"
                        src={plan?.icon}
                        alt="HomeChef360"
                        height="90px"
                        width="90px"
                      />
                    ) : null}
                    <h5 className="plan-type fs-12 text-success mb-0 fw-bold text-center mb-2">
                      {plan.name}
                    </h5>
                    {plan.description && (
                      <p className="fs-12 justify-content-center text-justify mt-2" style={{ maxWidth: "500px" }}>
                        {plan.description} 
                        </p>
                    )}
                  </div>

                  {plan.isPlanActive && plan.plantype.planTypeName === "!Free Plan" && (
                    <div className="subscription-options mb-3">
                      <label
                        className="form-check-label fw-bold mt-2 badge bg-danger ms-9"
                        style={{
                          backgroundColor: "#f8d7da",
                          padding: "12px",
                          borderRadius: "12px",
                        }}
                      >
                        {plan?.plan_start_date && (
                          <span>
                            Validity:{" "}
                            {new Date(plan.plan_start_date).toLocaleDateString("en-US", {
                              year: "numeric",
                              month: "short",
                              day: "numeric",
                            })}{" "}
                            to{" "}
                            {new Date(plan.plan_end_date).toLocaleDateString("en-US", {
                              year: "numeric",
                              month: "short",
                              day: "numeric",
                            })}
                          </span>
                        )}
                      </label>
                    </div>
                  )}

                  {plan.plan_details.map((detail: PlanDetail) => (
                    <div key={detail.validity} className="form-check">
                      {detail.type == 1 ? (
                        <>
                          <input
                            type="radio"
                            id={`plan-${plan.id}-${detail.validity}`}
                            name={`plan-${plan.id}`}
                            value={detail.type}
                            className="form-check-input"
                            onChange={() => {
                              handlePlanOptionChange(
                                plan.id,
                                detail.amount,
                                detail.validity,
                                detail.validityCount,
                                detail.type,
                                detail.isActive,
                                detail.is_trial,
                                detail.originalAmount,

                              );
                            }}
                            checked={plan.id === subscribingPlan || selectedPlanOption[plan.id]?.type === detail.type}
                            disabled={plan.isPlanActive === true && detail.isActive === true}
                          />
                          <label
                            className={`form-check-label ${detail.type === 1 && detail.isActive ? "text-danger" : "text-success fw-bold"}`}
                            htmlFor={`plan-${plan.id}-${detail.validity}`}
                          >
                            ${detail.amount} for {detail.validity}
                          </label>
                        </>
                      ) : (
                        <span></span>
                      )}
                    </div>
                  ))}

                  <div className="plan-details mt-3 mb-2 mt-3">
                    <strong className="text-danger">Features:</strong>
                    <ul className="list-unstyled">
                      {plan.featuresDetails
                      
                      .map((feature: any) => (
                        <li key={feature.id} className="d-flex align-items-center my-2">
                          <img
                            src="../../../assets/images/vector.png"
                            alt="tick"
                            className="me-2"
                            style={{ width: "15px", height: "15px" }}
                          />
                          <span className="me-3" style={{ fontSize: "14px" }}>
                            {feature.name}&nbsp;
                            {feature.description && (
                              <i
                                className="material-symbols-outlined info-ico me-3"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title={feature?.description}
                                style={{
                                  cursor: "pointer",
                                  position: "relative",
                                  top: "4px",
                                  fontSize: "16px",
                                  width: "20px",
                                  height: "20px",
                                }}
                                onClick={() => handleInfoClick(feature.id)}
                              >
                                info
                              </i>
                            )}
                            {activeFeatureId === feature.id && (
                              <div
                                style={{
                                  background: "#fff",
                                  padding: "10px",
                                  borderRadius: "5px",
                                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                                  border: "1px solid #ccc", // Gray border
                                  position: "absolute",
                                  zIndex: 1,
                                }}
                              >
                                {feature.description}
                              </div>
                            )}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>

                  {/* {subscribedActivePlan?.id === plan.id && ( */}
                  <div className="col-lg-12 plan-footer d-flex justify-content-center align-items-center mt-3 mb-2">
                    <button
                      className="btn rounded-5 me-2 btn-outline-success"
                      onClick={() => handleCheckout(plan.id)}
                      disabled={loading}
                    >
                      {subscribingPlan === plan.id ? "Subscribing..." : "Buy Now"}
                    </button>
                  </div>
                  {/* )} */}

                </div>
              </div>
            ))
        ) : (
          <div className="col-12 text-center">No plans available.</div>
        )}
      </div>
    </section>

  );
};

export default SalesPageChefsPromoteKit;
