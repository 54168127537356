import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Card, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { ProfileDetails, unsubscribePlan } from "../core/_requests";
import { useModalContext } from "../../Context/ModalContext";

interface PlanUnsubscribeProps {
    showFinalModal?: boolean;
}
const PlanContext = React.createContext({
    id: null,
    status: false,
    setId: (id: string) => { },
    setStatus: (status: boolean) => { },
});
const PlanUnsubscribe: React.FC<PlanUnsubscribeProps> = ({ showFinalModal }) => {
    const [showFinalConfirmationModal, setShowFinalConfirmationModal] = useState(false);
    const { setShowUnsubscribeModal } = useModalContext();
    const handleCloseFinalConfirmationModal = () => setShowFinalConfirmationModal(false);

    console.log("*********************************************************************");

    console.log("showModal", showFinalModal);
    console.log("*********************************************************************");

    const [status, setStatus] = useState(false);
    const navigate = useNavigate();
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const user_id = id;
    const [tooltipVisible, setTooltipVisible] = useState(false);
    const [showFeaturesModal, setShowFeaturesModal] = useState(showFinalModal);
    const [listPlan, setListPlan] = useState<any>(null);
    const [showCardDetails, setShowCardDetails] = useState(false);
    console.log("*********************************************************************")
    console.log("listPlan unsubscription", listPlan)
    console.log("*********************************************************************")
    useEffect(() => {
        const fetchProfileDetails = async () => {
            try {
                const res = await ProfileDetails(user_id);
                setListPlan(res?.data?.user_plan_subscriptions[0]);

            } catch (error) {
                console.error("Error fetching profile details:", error);
            }
        };

        fetchProfileDetails();
    }, [user_id]);

    const handleShowUnsubscribeModal = () => {
        setShowFeaturesModal(true);
    };

    const handleCloseUnsubscribeModal = () => {
        setShowUnsubscribeModal(false);
        setShowFeaturesModal(false);
        setShowFinalConfirmationModal(false); // Default to false if undefined
        navigate(`/info/${id}`);

    };
    const handleProceedWithFeaturesWarning = () => {
        setShowFeaturesModal(false);
        setShowFinalConfirmationModal(true); // Directly proceed to final confirmation

    };
    const toggleTooltip = () => {
        setTooltipVisible(!tooltipVisible); // Toggle visibility on click
    };
    // Function to toggle card visibility
    const toggleCardDetails = () => {
        setShowCardDetails(!showCardDetails);
    };

    // Function to close the card
    const closeCardDetails = () => {
        setShowCardDetails(false);
    };

    const handleProceedWithUnsubscribe = async () => {
        if (!listPlan) {
            toast.error("No plan selected for unsubscription.");
            return;
        }

        const { plan_id, amount, type, payment_id, validity } = listPlan;
        const data = { planId: plan_id, user_id, amount, type, validity, payment_id };

        try {
            setLoading(true);
            const unsubscribeResponse = await unsubscribePlan(data);
            setStatus(true);
            if (unsubscribeResponse.status == true) {
                toast.success(unsubscribeResponse?.message);
                setShowFinalConfirmationModal(false);
                navigate(`/info/${user_id}`, { replace: true });
                window.location.reload();
            } else {
                toast.error(unsubscribeResponse?.message);

                console.error("Unsubscribe error:", unsubscribeResponse);
            }
        } catch (error) {
            console.error("Error during unsubscribe process:", error);
            toast.error("Error during unsubscribe process. Please try again.");
        } finally {
            setLoading(false);

        }
    };

    const handleUpgrade = () => {
        navigate(`/info/${user_id}`);
    };

    return (
        <div >

            <div className="row w-100">
                <div className="col-12 d-flex justify-content-between align-items-center">
                    {/* Plan Details with Image and Info Icon */}
                    <div className="d-flex align-items-center">
                        <img
                            src="../../../assets/images/vector.png"
                            alt="tick"
                            className="me-2"
                            style={{ width: "15px", height: "15px" }}
                        />  {/* Action (Unsubscribe/Upgrade) */}
                        <div>
                            {listPlan?.originalAmount === 0 ? (
                                <small
                                    className="text-success"
                                    style={{
                                        cursor: "pointer",
                                        textDecoration: "underline",
                                    }}
                                    onClick={handleUpgrade}
                                >
                                    Upgrade
                                </small>
                            ) : (
                                <small
                                    className="text-danger"
                                    style={{
                                        cursor: "pointer",
                                        textDecoration: "underline",
                                    }}
                                    onClick={handleShowUnsubscribeModal}
                                >
                                    Unsubscribe
                                </small>
                            )}
                        </div>
                        <span style={{ fontSize: "14px" }} className="d-flex align-items-center">
                            {listPlan?.originalAmount !== 0 && (
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip>
                                            Name: {listPlan?.plan.name}
                                            <br />
                                            Amount: {listPlan?.originalAmount ? listPlan.originalAmount.toFixed(2) : '0.00'}
                                            ({listPlan?.validityCount})
                                            <br />
                                            Start Date: {new Date(listPlan?.plan_start_date).toLocaleDateString("en-US", {
                                                month: "short",
                                                day: "numeric",
                                                year: "numeric",
                                            })}
                                            <br />
                                            End Date: {new Date(listPlan?.plan_end_date).toLocaleDateString("en-US", {
                                                month: "short",
                                                day: "numeric",
                                                year: "numeric",
                                            })}
                                        </Tooltip>
                                    }
                                >
                                    <i
                                        className="material-symbols-outlined info-ico ms-2"
                                        style={{
                                            cursor: "pointer",
                                            fontSize: "16px",
                                            verticalAlign: "middle",
                                        }}

                                        onClick={toggleCardDetails}
                                    >
                                        info
                                    </i>
                                </OverlayTrigger>
                            )}
                        </span>
                    </div>

                    {/* Tooltip Card Details */}
                    {showCardDetails && (
                        <div>
                            <Modal show={showCardDetails} onHide={closeCardDetails} centered>
                                <Modal.Header closeButton>
                                    <Modal.Title className="text-center fw-bold text-success">Plan Details</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Card>
                                        <Card.Body>

                                            <div className="plan-details">
                                                <div className="detail-item">
                                                    <strong>Name :</strong>
                                                    <span>{listPlan?.plan?.name}</span>
                                                </div>
                                                <div className="detail-item">
                                                    <strong>Amount :</strong>
                                                    <span>$ {listPlan?.originalAmount ? listPlan.originalAmount.toFixed(2) : '0.00'} {" "} ({listPlan?.validityCount}) </span>
                                                </div>
                                                <div className="detail-item">
                                                    <strong>Start Date :</strong>
                                                    <span>{new Date(listPlan?.plan_start_date).toLocaleDateString("en-US", { month: "short", day: "numeric", year: "numeric" })}</span>
                                                </div>
                                                <div className="detail-item">
                                                    <strong>End Date :</strong>
                                                    <span>{new Date(listPlan?.plan_end_date).toLocaleDateString("en-US", { month: "short", day: "numeric", year: "numeric" })}</span>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Modal.Body>
                                <Modal.Footer className="d-flex justify-content-center">
                                    <Button variant="secondary rounded-5 px-5 mt-2 mb-2" onClick={closeCardDetails}>
                                        Close
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    )}

                </div>
            </div>


            {/* Features Loss Warning Modal */}
            <Modal show={showFeaturesModal} onHide={handleCloseUnsubscribeModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">Before you go
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="mb-3 mt-3">We're sorry you're thinking of cancelling you <b>{listPlan?.plan.name}</b> plan
                    </p>

                    <p className="text-danger">

                        You'll lose access to order offers, free delivery, discounts, and all premium features.

                    </p>


                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center">
                    <Button className="btn btn-cancel rounded-5 px-5 mt-2 mb-2" onClick={handleCloseUnsubscribeModal}>
                        Changed my mind
                    </Button>
                    <Button className="btn btn-primary rounded-5 px-5 mt-2 mb-2" onClick={handleProceedWithFeaturesWarning}>
                        I want to Cancel

                    </Button>
                </Modal.Footer>
            </Modal>


            {/* Final Confirmation Modal */}
            <Modal show={showFinalConfirmationModal} onHide={handleCloseFinalConfirmationModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">Sorry to see you go :(
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Your <b>{listPlan?.plan.name}</b> plan subscription is paid until <b>{new Date(listPlan?.plan_end_date).toLocaleDateString('en-US', {
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric'
                    })}</b>.

                    </p>
                   
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center">
                    <Button className="btn btn-cancel rounded-5 px-5 mt-2 mb-2" onClick={handleCloseFinalConfirmationModal}>
                        Stay on
                    </Button>
                    <Button className="btn btn-primary rounded-5 px-5 mt-2 mb-2" onClick={handleProceedWithUnsubscribe}>
                        Cancel Subscription
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>
    );
};

export default PlanUnsubscribe;
