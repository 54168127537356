import { FC } from "react";

interface OfferBannerProps {
  offerText: string | null; // `offerText` can be a string or null
}

const OfferBanner: FC<OfferBannerProps> = ({ offerText }) => {
  // Do not render the banner if no offer is active
  if (!offerText) {
    return null;
  }

  return (
<div
  style={{
    textAlign: "center", // Centering the text inside the parent container
    display: "flex", // Flexbox to center the element horizontally
    justifyContent: "center", // Centers content horizontally
    marginBottom :"15px"
  }}
>
  <h5
    style={{
      fontSize: "15px",
      fontWeight: "bold",
      color: "rgb(245 244 244)",
      textAlign: "center", // Ensures text inside the h5 is centered
      backgroundColor: "rgb(51 115 8)",
      padding: "10px",
      borderRadius: "5px",
      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
      display: "inline-block", // Makes h5 only as wide as its content
      maxWidth: "100%", // Prevents overflow
      width: "auto", // Allows h5 to adjust based on content
    }}
  >
    {offerText}
  </h5>
</div>


  );
};

export default OfferBanner;
