import { useNavigate } from "react-router-dom";

const PaymentCancel = () => {
  const navigate = useNavigate();
  const userId = localStorage.getItem("userId"); 

  return (
    <section className="container-fluid">
      <div className="overlay"></div>
      <div className="drawer small add-dish">
        <div className="alert-wrap">
          <img
            className="ico mb-2"
            src="../assets/images/alert-warning-ico.svg"
            alt="cancel"
          />
          {/* <h1 className="mb-4">Awesome!</h1> */}
          <p className="mid-gray mb-2 mt-3 text-center ps-3">
            <small>
              The payment was unexpectedly canceled, indicating that the user
              chose to terminate the transaction before completion. This may be
              due to various reasons, including payment method issues or personal
              preferences.
            </small>
          </p>
          <button
            className="btn btn-cancel rounded-5 mt-3 btn-outline-primary mb-3"
            onClick={() => navigate(`/pk/subscriptions`)} 
          >
            Back to Home
          </button>
        </div>
      </div>
    </section>
  );
};

export default PaymentCancel;
