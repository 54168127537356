import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../sidebar/Footer";
import SocialMediaIcons from "./SocialMedia";

const WelcomePage = () => {
    const navigate = useNavigate();
    const GoButton = () => {
        navigate("/pk/subscription");
    }
    return (
        <section className="container">
            {/* Header Section */}
            <header className="text-center py-0">
                <img
                    className="img-fluid"
                    src="../assets/images/homechef-logo.svg"
                    alt="HomeChef360 Logo"
                    style={{ height: "80px" }}
                />
                <h1 className="fw-bold text-success mt-4">Welcome to HomeChef360 Affiliate</h1>
                <p className="text-muted mt-3 ">
                    Elevate your culinary journey with subscription plans designed for chefs and food enthusiasts alike.
                </p>
            </header>

            {/* Description Section */}
            <section className="text-center my-1 ">
                <p className="text-muted py-5">
                    <strong className="text-success">HomeChef360</strong> offers flexible subscription plans to meet diverse needs. Whether you're a chef
                    growing your business or a food lover exploring gourmet delights, our platform provides all the tools and opportunities you need to succeed.
                </p>
                <button className="btn btn-outline-success mt-3 rounded-5 px-4 md-3"
                    onClick={GoButton}>Go</button><br />
            </section>

            {/* Footer Section */}
            <div className="mt-5 my-5">   <Footer /></div>

        </section>
    );
};

export default WelcomePage;
